import type { IBox } from '~/types/models/box.interface'

export const getFullName = (user: any) => {
	return (
		user?.personalInfo.firstName[0].toUpperCase() +
		user?.personalInfo.firstName.substring(1) +
		' ' +
		user?.personalInfo.lastName[0].toUpperCase() +
		user?.personalInfo.lastName.substring(1)
	)
}

export const formatCurrency = (value: number) => {
	return value.toLocaleString('pt-br', {
		style: 'currency',
		currency: 'BRL',
	})
}

export const getBoxPrice = (box: IBox) => {
	return box.prices.find(price => price.currency === 'brl')?.price
}

export const captureEvent = (event: string, properties = {}) => {
	const config = useRuntimeConfig()

	if (config.public.enablePosthog) {
		const { $posthog } = useNuxtApp()

		$posthog?.capture(event, properties)
	}
}
